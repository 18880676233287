import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import { JobFeature } from "../components/job-components"
import SuccessStory from "../components/success-story"

const SuccessStoryGreehill = ({ data }) => {
  return (
    <SuccessStory
      title="Green asset management without privacy concerns"
      customerName="greehill"
      customerLocation="Hungary"
      customerDescription="greehill accelerates the digital transformation of green asset management and makes cities greener, safer and more resilient."
      customerIndustries="Surveying, Machine Learning"
      link="https://www.greehill.com/"
      logo=""
      style={{
        background: "url(/images/greehill-pointcloud.png) no-repeat",
        backgroundPositionY: "center",
        backgroundSize: "cover",
      }}
    >
      <h2 className="my-3">Challenge</h2>
      <p>
        <strong>
          Making cities greener, safer and more resilient is widely accepted as
          a top priority among citizens and officials. In particular, trees help
          to mitigate atmospheric pollution, reduce the impact of traffic on air
          quality and increase the attractiveness of routes for exercise and
          sustainable transport.
        </strong>
      </p>
      <p>
        greehill provides the data and platform to monitor and optimise the
        health and safety of urban trees. Detailed 3D digital twins and
        AI-powered recognition models are capable of identifying trees and
        determining their exact structure and placement in a dynamic urban
        environment.
      </p>
      <figure className="figure">
      <Img
        fluid={data.greehillPointcloud.childImageSharp.fluid}
        className="rounded my-3"
      />
      <figcaption className="figure-caption">A pointcloud generated by greehill.</figcaption>
      </figure>
      <p>
        To achieve this, greehill gathers massive amounts of image data using a
        combination of high-resolution cameras, mobile and terrestrial laser
        scanning devices. This imagery also contains personal information (e.g.
        faces, licence plates) that needs to be blurred to comply with data
        privacy regulations.
      </p>
      <h2 className="my-3">Solution</h2>
      <p>
        Due to the large amount of data, Celantur’s container was deployed into
        an Azure environment, providing an automated and scalable solution to
        blur the sensitive information contained on the images. Therefore,
        greehill’s team can focus on their core expertise: providing actionable
        insights about urban trees for their clients, making cities healthier
        and a better place to live.
      </p>

      <h2 className="my-3">Celantur's contribution</h2>
      <JobFeature>
        Fully-automated anonymization of hundred thousands of high-resolution
        street-level images
      </JobFeature>
      <JobFeature>Privacy of citizens protected</JobFeature>
    </SuccessStory>
  )
}

export default SuccessStoryGreehill

export const query = graphql`
  query {
    greehillPointcloud: file(relativePath: { eq: "greehill-pointcloud.png" }) {
      childImageSharp {
        fluid(maxWidth: 960) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
